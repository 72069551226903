<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="produtos"
            :headers="headers"
            :slots="['item.fornecedor','item.preco_venda_sugerido']"
            :actions="actions"
            :permission="'list_produto'"
            :sort-by="['codigo','nome']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template
              v-if="$store.getters.hasPermission('inserir_produto')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  color="primary"
                  block
                  title="Cadastrar novo produto"
                  rounded
                  class="text-none text-white"
                  @click="$router.push({ path: 'produtos/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import ProdutosStore, { BOOTSTRAP, INATIVAR_PRODUTO } from '@/store/modules/produtos'
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    components: {
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar produto',
            color: 'info darken-3',
            click: item => this.editarProduto(item),
            icon: 'mdi-pencil',
            permission: 'editar_produto',
          },
          {
            title: 'Excluir Produto',
            color: 'red darken-3',
            click: item => this.INATIVAR_PRODUTO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_produto',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, floatingfilter: false, groupable: false, width: '40px' },
          { align: 'right', sortable: true, floatingfilter: true, groupable: false, text: 'Código', value: 'codigo', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: true, text: 'Unidade', value: 'unidade.nome', width: 'auto', report: true },
          { align: 'right', sortable: false, floatingfilter: false, groupable: false, text: 'Volume', value: 'volume', width: 'auto', report: true, styleReport: { halign: 'right', type: 'number' }, styleXLSX: { type: 'number' } },
          { align: 'left', sortable: true, floatingfilter: true, groupable: true, text: 'Categoria', value: 'categoria.nome', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: true, text: 'Marca', value: 'marca', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: true, text: 'Fornecedor', value: 'fornecedor.nome', width: 'auto' },
        ],

      }
    },
    computed: {
      ...mapState('produtos', ['loading', 'produtos']),
    },
    async created () {
      if (!this.$store.hasModule('produtos')) { this.$store.registerModule('produtos', ProdutosStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('produtos', [BOOTSTRAP, INATIVAR_PRODUTO]),
      ...mapGetters(['hasPermission']),
      editarProduto ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/cdc/produtos/alterar`,
          query: { id },
        })
      },
    },
  }

</script>
